import React from "react";
import AdminLayout from "../../../../../components/admin/adminLayout";
import PenaltyUnitsJurisdictionPenaltyUnitComponent from "../../../../../components/admin/penaltyUnitsJurisdictionPenaltyUnitComponent";
import Apollo from "../../../../../components/apollo";
import
{
  penaltyUnitCalculatorLegislationQuery,
  getJurisdictionById,
  penaltyUnitCalculatorLegislationCreateMutation,
  penaltyUnitCalculatorLegislationUpdateMutation,
  penaltyUnitCalculatorLegislationDeleteMutation
}
from "../../../../../utils/staticQueryFragments";
import SEO from "../../../../../components/seo";
import WithPrivateRoute from "../../../../../components/admin/withPrivateRoute";
import WithLocation from "../../../../../components/withLocation";
import PenaltyUnitsJurisdictionPenaltyUnitSkeleton from "../../../../../components/admin/penaltyUnitsJurisdictionPenaltyUnitSkeletonComponent";

const ToolsPenaltyUnitsJurisdictionLegislation = (props) =>
{
  const penaltyUnitJurisdictionCreationMode = () =>
  {
    return !props.search.pu;
  };

  return (
    <AdminLayout>
      <SEO
        keywords = { [`LawChest`, `admin`, `tools`, `administration`, `penalty`, `unit`, `calculator`, `legislation`] }
        title = "Penalty Unit Calculator Legislation"
        isAdmin = { true }
      />
      <Apollo
        propsToInsert =
        {
          (
            queryResults,
            { mutation1, mutation1Results },
            { mutation2, mutation2Results },
            { mutation3, mutation3Results }
          ) => (
            {
              penaltyUnitLegislationQueryResults: queryResults,
              updatePUCL: (
                id,
                commencementDate,
                value,
                authorityName,
                authorityLink
              ) =>
              {
                mutation1(
                  {
                    variables:
                    {
                      id: id,
                      commencementDate: commencementDate,
                      value: value,
                      authorityName: authorityName,
                      authorityLink: authorityLink
                    }
                  }
                );
              },
              updatePUCLResults: mutation1Results,
              deletePUCL: (id) =>
              {
                mutation2(
                  {
                    variables:
                    {
                      id: id
                    }
                  }
                );
              },
              deletePUCLResults: mutation2Results,
              createPUCL: (
                jurisdictionId,
                commencementDate,
                value,
                authorityName,
                authorityLink
              ) =>
              {
                mutation3(
                  {
                    variables:
                    {
                      jurisdictionId: jurisdictionId,
                      commencementDate: commencementDate,
                      value: value,
                      authorityName: authorityName,
                      authorityLink: authorityLink
                    }
                  }
                );
              },
              createPUCLResults: mutation3Results
            }
          )
        }
        query =
        {
          !penaltyUnitJurisdictionCreationMode()
            ? penaltyUnitCalculatorLegislationQuery
            : undefined
        }
        queryOptions =
        {
          !penaltyUnitJurisdictionCreationMode()
            ?
              {
                variables: { id: props.search.pu }
              }
            : undefined
        }
        mutation1 = { penaltyUnitCalculatorLegislationUpdateMutation }
        mutation2 = { penaltyUnitCalculatorLegislationDeleteMutation }
        mutation2Options =
        {
          {
            update(cache, { data: { deletePenaltyUnitMultiplier } })
            {
              const cachedJurisdictionByIdData = cache.readQuery(
                {
                  query: getJurisdictionById,
                  variables: { id: props.search.j },
                }
              );

              cache.writeQuery(
                {
                  query: getJurisdictionById,
                  variables: { id: props.search.j },
                  data:
                  {
                    jurisdictionById:
                    {
                      __typename: cachedJurisdictionByIdData.jurisdictionById.__typename,
                      id: cachedJurisdictionByIdData.jurisdictionById.id,
                      name: cachedJurisdictionByIdData.jurisdictionById.name,
                      penaltyUnitMultipliers: [
                        ...cachedJurisdictionByIdData.jurisdictionById.penaltyUnitMultipliers.filter(m => m.id !== deletePenaltyUnitMultiplier.id)
                      ]
                    }
                  }
                }
              );
            }
          }
        }
        mutation3 = { penaltyUnitCalculatorLegislationCreateMutation }
        mutation3Options =
        {
          {
            update(cache, { data: { createPenaltyUnitMultiplier } })
            {
              const cachedJurisdictionByIdData = cache.readQuery(
                {
                  query: getJurisdictionById,
                  variables: { id: props.search.j },
                }
              );

              cache.writeQuery(
                {
                  query: getJurisdictionById,
                  variables: { id: props.search.j },
                  data:
                  {
                    jurisdictionById:
                    {
                      __typename: cachedJurisdictionByIdData.jurisdictionById.__typename,
                      id: cachedJurisdictionByIdData.jurisdictionById.id,
                      name: cachedJurisdictionByIdData.jurisdictionById.name,
                      penaltyUnitMultipliers: [
                        {
                          __typename: createPenaltyUnitMultiplier.__typename,
                          id: createPenaltyUnitMultiplier.id,
                          jurisdictionId: props.search.j,
                          commencementDate: createPenaltyUnitMultiplier.commencementDate,
                          value: createPenaltyUnitMultiplier.value,
                          authorityName: createPenaltyUnitMultiplier.authorityName,
                          authorityLink: createPenaltyUnitMultiplier.authorityLink
                        },
                        ...cachedJurisdictionByIdData.jurisdictionById.penaltyUnitMultipliers
                      ]
                    }
                  }
                }
              );
            }
          }
        }
        loadingComponent = { PenaltyUnitsJurisdictionPenaltyUnitSkeleton }
      >
        <PenaltyUnitsJurisdictionPenaltyUnitComponent
          jurisdictionId = { props.search.j }
          penaltyUnitId = { props.search.pu }
          penaltyUnitJurisdictionCreationMode = { penaltyUnitJurisdictionCreationMode }
        />
      </Apollo>
    </AdminLayout>
  );
};

export default WithPrivateRoute(WithLocation(ToolsPenaltyUnitsJurisdictionLegislation));