import React, { useState } from "react";
import { navigate } from "gatsby";
import NumberFormat from "react-number-format";
import { isValidHttpUrl } from "../../utils/helpers";
import GoBack from "../goBack";

const PenaltyUnitsJurisdictionPenaltyUnitComponent = (props) =>
{
  const legislationData = props.penaltyUnitLegislationQueryResults
    ? { ...props.penaltyUnitLegislationQueryResults.data.penaltyUnitMultiplierById }
    : {};

  const [legislationTitle, setLegislationTitle] = useState(legislationData.authorityName);
  const [legislationLink, setLegislationLink] = useState(legislationData.authorityLink);
  const [penaltyUnitValue, setPenaltyUnitValue] = useState(legislationData.value);
  const [commencementDate, setCommencementDate] = useState(legislationData.commencementDate);
  const [validation, setValidation] = useState(
    {
      isLegislationTitleValid: true,
      isLegislationLinkValid: true,
      isPenaltyUnitValueValid: true,
      isCommencementDateValid: true
    }
  );

  const handleSubmit = e =>
  {
    e.preventDefault();

    let localIsLegislationTitleValid = true;
    let localIsLegislationLinkValid = true;
    let localIsPenaltyUnitValueValid = true;
    let localIsCommencementDateValid = true;

    if (!penaltyUnitValue)
    {
      localIsPenaltyUnitValueValid = false;
    }

    if (!commencementDate)
    {
      localIsCommencementDateValid = false;
    }

    if (!legislationTitle)
    {
      localIsLegislationTitleValid = false;
    }

    if (legislationLink && !isValidHttpUrl(legislationLink))
    {
      localIsLegislationLinkValid = false;
    }

    setValidation(
      {
        isLegislationTitleValid: localIsLegislationTitleValid,
        isLegislationLinkValid: localIsLegislationLinkValid,
        isPenaltyUnitValueValid: localIsPenaltyUnitValueValid,
        isCommencementDateValid: localIsCommencementDateValid
      }
    );

    if (localIsLegislationTitleValid
      && localIsLegislationLinkValid
      && localIsPenaltyUnitValueValid
      && localIsCommencementDateValid)
    {
      if (props.penaltyUnitJurisdictionCreationMode())
      {
        props.createPUCL(
          props.jurisdictionId,
          commencementDate,
          penaltyUnitValue,
          legislationTitle,
          legislationLink
        );
      }
      else
      {
        props.updatePUCL(
          legislationData.id,
          commencementDate,
          penaltyUnitValue,
          legislationTitle,
          legislationLink
        );
      }

      navigate(`/admin/tools/penaltyunits/jurisdiction?j=${props.jurisdictionId}`);
    }
  };
  
  const handleDelete = e =>
  {
    e.preventDefault();

    props.deletePUCL(legislationData.id);

    navigate(`/admin/tools/penaltyunits/jurisdiction?j=${props.jurisdictionId}`);
  };

  // DatePicker, ValueInput, Legislation Title, Legislation Link

  return (
    <div>
      <GoBack
        text = "← Back to Jurisdiction"
        destination = { `/admin/tools/penaltyunits/jurisdiction?j=${props.jurisdictionId}` }
      />
      <div className = "text-center">
        <h2 className = "bg-yellow-400 text-2xl font-bold inline-block mb-8 p-3">
          Legislation Information
        </h2>
      </div>
      <form>
        <div
          className = "mb-6"
        >
          <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="legislationTitle"
          >
            Title
          </label>

          <div
            className = "px-3 py-2"
          >
            <input
              id = "legislationTitle"
              className="appearance-none block border-gray-400 border px-3 py-2 rounded text-gray-700 w-full"
              type = "text"
              defaultValue = { legislationTitle }
              onChange = { e => { setLegislationTitle(e.target.value); } }
            />
          </div>

          {
            !validation.isLegislationTitleValid &&
            <p
              className = "px-3 text-red-500 text-xs italic"
            >
              Please enter the Legislation's Title.
            </p>
          }
        </div>

        <div
          className = "mb-6"
        >
          <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="legislationLink"
          >
            Link
          </label>

          <div
            className = "px-3 py-2"
          >
            <input
              id = "legislationLink"
              className="appearance-none block border-gray-400 border px-3 py-2 rounded text-gray-700 w-full"
              type = "text"
              defaultValue = { legislationLink }
              onChange = { e => { setLegislationLink(e.target.value); } }
            />
          </div>

          {
            !validation.isLegislationLinkValid &&
            <p
              className = "px-3 text-red-500 text-xs italic"
            >
              Please enter the Legislation's Link.
            </p>
          }
        </div>

        <div
          className = "mb-6"
        >
          <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="penaltyUnitValue"
          >
            Penalty Unit Value
          </label>

          <div
            className = "px-3 py-2"
          >
            <NumberFormat
              id="penaltyUnitValue"
              className="appearance-none block border-gray-400 border px-3 py-2 rounded text-gray-700 w-full"
              defaultValue = { penaltyUnitValue }
              thousandSeparator = { true }
              inputMode = "numeric"
              prefix = "$"
              onValueChange = { ({formattedValue, value}) => { setPenaltyUnitValue(Number(value)); } }
            />
          </div>

          {
            !validation.isPenaltyUnitValueValid &&
            <p
              className = "px-3 text-red-500 text-xs italic"
            >
              Please enter the Penalty Unit Value.
            </p>
          }
        </div>

        <div
          className = "mb-6"
        >
          <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="commencementDate"
          >
            Commencement Date
          </label>

          <div
            className = "px-3 py-2"
          >
            <input
              id = "commencementDate"
              className="appearance-none block border-gray-400 border px-3 py-2 rounded text-gray-700 w-full"
              type = "date"
              defaultValue = { commencementDate }
              onChange = { e => { setCommencementDate(e.target.value); } }
            />
          </div>

          {
            !validation.isCommencementDateValid &&
            <p
              className = "px-3 text-red-500 text-xs italic"
            >
              Please enter the Commencement Date.
            </p>
          }
        </div>
      
        {
          props.penaltyUnitJurisdictionCreationMode() &&
            <div
              className = "text-center sm:text-left"
            >
              <button
                className = "border-b-4 border-gray-800 hover:border-gray-700 bg-gray-700 hover:bg-gray-600 font-bold px-4 py-2 mr-2 rounded text-sm text-white"
                type = "button"
                onClick = { e => handleSubmit(e) }
              >
                Create
              </button>
            </div>
        }
        {
          !props.penaltyUnitJurisdictionCreationMode() &&
            <div
              className = "text-center sm:text-left"
            >
              <button
                className = "border-b-4 border-gray-800 hover:border-gray-700 bg-gray-700 hover:bg-gray-600 font-bold px-4 py-2 mr-2 rounded text-sm text-white"
                type = "button"
                onClick = { e => handleSubmit(e) }
              >
                Update
              </button>
            </div>
        }
      </form>
      {
        !props.penaltyUnitJurisdictionCreationMode() &&
          <div>
            <div className = "text-center">
              <h2 className = "bg-yellow-400 text-2xl font-bold inline-block mb-8 p-3">
                Danger Zone
              </h2>
            </div>
            <div
              className = "text-center sm:text-left"
            >
              <button
                className = "border-b-4 border-gray-800 hover:border-gray-700 bg-gray-700 hover:bg-gray-600 font-bold px-4 py-2 rounded text-sm text-white"
                type = "button"
                onClick = { e => handleDelete(e) }
              >
                Delete Legislation
              </button>
            </div>
          </div>
      }
    </div>
  );
};

export default PenaltyUnitsJurisdictionPenaltyUnitComponent;