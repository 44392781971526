import React from "react";
import Skeleton from 'react-loading-skeleton';

const PenaltyUnitsJurisdictionPenaltyUnitSkeleton = () =>
{
  return (
    <div>
      <div className = "h-3 w-1/6 mb-4">
        <Skeleton className = "h-full" />
      </div>
      <div className = "h-16 w-full mb-8">
        <Skeleton className = "h-full" />
      </div>
      <div className = "mb-6">
        <div className = "h-3 w-1/6 mb-2">
          <Skeleton className = "h-full" />
        </div>
        <div className = "h-8 w-full">
          <Skeleton className = "h-full" />
        </div>
      </div>
      <div className = "mb-6">
        <div className = "h-3 w-2/6 mb-2">
          <Skeleton className = "h-full" />
        </div>
        <div className = "h-8 w-full">
          <Skeleton className = "h-full" />
        </div>
      </div>
      <div className = "mb-6">
        <div className = "h-3 w-3/6 mb-2">
          <Skeleton className = "h-full" />
        </div>
        <div className = "h-8 w-full">
          <Skeleton className = "h-full" />
        </div>
      </div>
      <div className = "mb-6">
        <div className = "h-3 w-2/6 mb-2">
          <Skeleton className = "h-full" />
        </div>
        <div className = "h-8 w-full">
          <Skeleton className = "h-full" />
        </div>
      </div>
      <div className = "mb-6">
        <div className = "h-8 w-1/6">
          <Skeleton className = "h-full" />
        </div>
      </div>
      <div className = "h-16 w-full mb-8">
        <Skeleton className = "h-full" />
      </div>
      <div className = "h-8 w-1/6">
        <Skeleton className = "h-full" />
      </div>
    </div>
  );
};

export default PenaltyUnitsJurisdictionPenaltyUnitSkeleton;